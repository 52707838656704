import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PlacesAutocomplete from 'react-places-autocomplete';
import StartAndEndTime from './StartAndEndTime';
import CandidateMenu from './CandidateMenu';
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import moment from 'moment';
import * as mutations from '../graphql/mutations';

const expected = ["candidates","slug","start","end","location"];
const optional = ["notes", "covering"]

class NewEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.props.location.state || {};
        this.state.valid = {};
        this.state.address = "";
        this.state.candidates = this.state.candidates || [undefined];
        this.updateTimes = this.updateTimes.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleLocationSelect = this.handleLocationSelect.bind(this);
    }

    componentDidMount() {
        let valid = this.state.valid;
        expected.concat(optional).forEach(field => {
            if (this.state[field] && !Array.isArray(this.state[field]) && (Number.isInteger(this.state[field]) || this.state[field].trim() !== '')) {
                valid[field] = true;
            }
            else {
                console.log(field, this.state[field])
            }
        })
        valid.candidates = !this.state.candidates.includes('false') && !this.state.candidates.includes(undefined);
        this.setState({valid})
    }

    handleLocationChange(e) {
        this.setState({address:e})
    }

    handleLocationSelect(e) {
        this.setState({address:e})
        this.updateState('location', e)
    }

    handleLocationBlur(suggestions) {
        if (suggestions.length > 0) {
            this.setState({address:suggestions[0].description})
            this.updateState('location', suggestions[0].description)
        }
    }

    updateTimes(start, end) {
        let valid = this.state.valid;
        if ((start > Date.now()) && (end > start)) {
            valid.start = true;
            valid.end = true;
            this.setState({
                start,
                end,
                valid
            });
        }
        else {
            valid.start = false;
            valid.end = false;
            this.setState({
                start: null,
                end: null,
                valid
            })
        }
    }

    validateCandidates(candidates) {
        let valid = this.state.valid;
        valid.candidates = !candidates.includes('false') && !candidates.includes(undefined);
        this.setState({ valid })
    }

    updateCandidate(index, e) {        
        let { candidates } = this.state;
        candidates[index] = e.target.value;
        this.validateCandidates(candidates);
        this.setState({ candidates });
    }

    updateState(field, e) {
        const val = (typeof e === "string")?e:e.target.value
        
        let valid = this.state.valid;
        if (val.trim() === '' || val === "false") {
            valid[field] = false;
        }
        else {
            valid[field] = true;
        }

        this.setState({
            valid,
            [field]:val
        });
    }

    isEnabled() {        
        const hasInvalid = expected.some(field => {
            return !this.state.valid[field];
        })
        
        return !hasInvalid
    }

    hasError(field) {
        return (this.state.valid[field] === false)
    }

    render() {
        const isEnabled = this.isEnabled();
        console.log(this.state.valid)
        return (
            <>
                <h2><FontAwesomeIcon icon="calendar-plus" /> New Event</h2>
                <div className="form">
                    <Connect mutation={graphqlOperation(mutations.createEvent)}>
                        {({mutation }) => (
                            <>
                                {this.state.candidates.map((candidate, i) => 
                                <p className={`formRow ${this.hasError('candidates')?'error':''} ${(i===0)?'reqd':''}`} key={`candidate_${i}`} >
                                    <label>Candidate{(this.state.candidates.length>1) && <> {i+1}</>}:</label>
                                        <CandidateMenu selected={candidate} onChange={e => this.updateCandidate(i, e)}/>
                                        {(i > 0) && <button className="lowprof" onClick={() => {
                                                const candidates = this.state.candidates.filter((_, idx) => i!==idx);
                                                this.setState({ candidates })
                                                this.validateCandidates(candidates);
                                            }}>X</button>}                                     
                                </p>
                                )}
                                <button className="asLink" onClick={() => {
                                        const candidates = [...this.state.candidates, undefined];
                                        this.setState({ candidates });
                                        this.validateCandidates(candidates);
                                    }}>Add Candidate</button>
                                <p className={this.hasError('slug')?"formRow error reqd":"formRow reqd"}>
                                    <input type="text" placeholder="Slug" onChange={e => this.updateState('slug', e)} />
                                </p>
                                <p className={this.hasError('start')?"formRow error reqd":"formRow reqd"}>
                                    <FontAwesomeIcon icon="clock" fixedWidth className="icon" />
                                    <StartAndEndTime start={Date.now()} end={Date.now()+3600000} updateFn={this.updateTimes} /> 
                                </p>
                                <div className={this.hasError('location')?"formRow error reqd":"formRow reqd"}>
                                    <FontAwesomeIcon icon="map-marker-alt" fixedWidth  className="icon" />
                                    <PlacesAutocomplete
                                        value={this.state.address}                                        
                                        onChange={this.handleLocationChange}
                                        onSelect={this.handleLocationSelect}
                                        searchOptions={{bounds:{
                                            east:-66.885444,
                                            north:49.384358,
                                            south:24.396308,
                                            west:-124.848974
                                        }}}
                                        highlightFirstSuggestion={true}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                            {...getInputProps({
                                                placeholder: 'Location',
                                                className: 'location-search-input',
                                            })}
                                            onBlur={() => this.handleLocationBlur(suggestions)}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                                );
                                            })}
                                            </div>
                                        </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                                <p className={this.hasError('covering')?"formRow error":"formRow"}>
                                    <FontAwesomeIcon icon="video" fixedWidth  className="icon" />
                                    <input type="text" placeholder="Source" onChange={e => this.updateState('covering', e)} />
                                </p>
                                <p className={this.hasError('notes')?"formRow error":"formRow"}>
                                    <FontAwesomeIcon icon="book" fixedWidth className="icon" />
                                    <textarea placeholder="Notes" onChange={e => this.updateState('notes', e)} />
                                </p>
                                <button className="cta" disabled={!isEnabled} onClick={async () => {
                                    
                                    const newEvent = await mutation({
                                        candidate_id: (this.state.candidates.length > 1)?'mult':this.state.candidates[0],
                                        candidates:(this.state.candidates.length > 1)?this.state.candidates:undefined,
                                        slug: this.state.slug,
                                        location: this.state.location,
                                        covering: this.state.covering,
                                        start: this.state.start/1000,
                                        end: this.state.end/1000,
                                        notes: this.state.notes,
                                        startDay: moment(this.state.start).startOf('day').unix()
                                    });
                                    this.props.history.push(`/candidates/${newEvent.data.createEvent.candidate.id}`)
                                }}>
                                    <FontAwesomeIcon icon="calendar-plus" /> Create Event
                                </button>
                            </>
                        )}
                    </Connect>
                </div>
            </>
        )
    }
}

export default NewEvent;