import React from 'react';
import {withRouter} from 'react-router-dom';
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/definedSubscriptions';
import moment from 'moment';
import './Schedule.css';

class Schedule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            time: moment(),
            initialScroll: false,
            method: this.props.match.params.method
        }
        this.schedule = React.createRef();
        this.now = React.createRef();
        this.updateTime = this.updateTime.bind(this)
    }

    componentDidMount() {
        setInterval(this.updateTime, 500);
        this.updateTime();        
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.method !== prevProps.match.params.method) {
            this.setState({
                method: this.props.match.params.method,
                initialScroll:false
            })
        }
    }

    fromTop(time) {
        return `${Math.round((time.unix() - (moment().startOf('day').unix()))/360)}%`
    }

    updateTime() { 
        const now = moment();
        this.setState({
            time: now,
        });
        if (this.schedule.current && this.now.current &&  !this.state.initialScroll){
            console.log('scroll')
            this.findNow();
        }
    }

    findNow() {
        this.schedule.current.scrollTo(this.now.current.offsetLeft, 0)
        this.setState({initialScroll: true})
    }

    chooseQuery(method) {
        if (method === "candidate") {
            return {
                query: queries.listCandidates,
                sub: subscriptions.allNewEvents
            }
        }
        else {
            return {
                query:queries.listLines,
                sub: subscriptions.allNewTransmissions
            };
        }
    }


    render() {
        return (
            <div className="scheduleWrap" ref={this.schedule}>
                <div className="topRow">
                        <div className="now" style={{left:Math.round(((moment() - moment().startOf('day'))/3600000)*125)+124-(this.now.current?(this.now.current.offsetWidth/2):0)}} ref={this.now}>{moment().format('LTS')}</div>
                        <div className="topRowCell staticTopLeft">{(this.state.method === "lines")?"Line":"Candidate"}</div>
                        {[...Array(24).keys()].map(hour => (
                            <div className="topRowCell" key={`top_${hour}`}>
                                {(hour>12)?(hour-12):(hour === 0)?12:hour}&nbsp;
                                {(hour>11)?"PM":"AM"}
                            </div>
                        ))}
                    </div>
                <div className="innerSchedule">
                    <div className="nowBar" style={{left:Math.round(((moment() - moment().startOf('day'))/3600000)*125)+124}}></div>
                    <Connect key={this.state.method}
                        query={graphqlOperation(this.chooseQuery(this.state.method).query, {startDay: moment().startOf('day').unix()})}
                        subscription={graphqlOperation(this.chooseQuery(this.state.method).sub)}
                        onSubscriptionMsg={(prev, newData) => {
                            let schedule = prev;
                            if (this.state.method === "candidate") {
                                const { newEvent } = newData;
                                if (moment(newEvent.start * 1000).startOf('day').unix() === moment().startOf('day').unix()) {
                                    schedule.listCandidates.forEach(candidate => {
                                        if (candidate.id === newEvent.candidate.id) {
                                            // candidate.events.push(newEvent);
                                            let idx = -1;
                                            candidate.events.forEach((event, i) => {
                                                if (event.id === newEvent.id) {
                                                    idx = i;
                                                }
                                            });
                                            if (idx >= 0) {
                                                candidate.events.splice(idx,1,newEvent);
                                            }
                                            else {
                                                candidate.events.push(newEvent);
                                            }
                                        }
                                    })
                                }
                                
                            }
                            else {
                                const { newTransmission } = newData; 
                                if (newTransmission.startDay === moment().startOf('day').unix()) { 
                                    schedule.listLines.forEach(line => {
                                        if (line.id === newTransmission.line.id) {
                                            line.transmissions.push(newTransmission)
                                        }
                                    })
                                }
                            }
                            return schedule;
                        }}>
                        
                        {({data, error, loading }) => {
                            if (error) return (<strong>Error</strong>)
                            if (loading) return (<strong>Loading...</strong>)

                            const listItems = (this.state.method === "lines")?data.listLines:data.listCandidates.filter(candidate => (candidate.active !== false));

                            return listItems.sort((a,b) => {
                                if (a.src > b.src) return 1;
                                if (a.src < b.src) return -1;
                                return 0;
                            }).map(item => (
                                <div className="row" key={item.id}>
                                    <div className="axis">{(this.state.method === "lines")?item.src:item.name}</div>
                                    {[...Array(24).keys()].map(hour => (
                                        <div className="cell" key={`cell_${item.id}_${hour}`}></div>
                                    ))}
                                    {(item.events || item.transmissions).map(event => (
                                        <div className="event"
                                            style={{
                                                left:Math.round(((event.start - moment().startOf('day').unix())/3600)*125)+124,
                                                width:Math.round((event.end-event.start)/3600)*125
                                            }}                                
                                            key={event.id}>
                                            {/* slightly different templates for events or transmissions */}

                                            {(this.state.method === "lines")?(
                                                // transmission
                                                <>
                                                <strong>{event.event.slug} ({event.event.candidate.name})</strong><br/>
                                                <small>
                                                    {moment(event.start*1000).format('h:mmA')} - {moment(event.end*1000).format('h:mmA')}
                                                </small><br/>
                                                </>
                                            ):(
                                                // event
                                                <>
                                                <strong>{event.slug}</strong><br/>
                                                <small>
                                                    {moment(event.start*1000).format('h:mmA')} - {moment(event.end*1000).format('h:mmA')}
                                                </small><br/>
                                                </>
                                            )}

                                        </div>
                                    ))}
                                </div>
                            ))

                        }}

                    </Connect>
                </div>
            </div>
        )
    }
}

export default withRouter( props => <Schedule {...props} />);