import React from 'react';
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { Link } from "react-router-dom";
import Transmission from './Transmission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/definedSubscriptions';

import './CandidateDetail.css';
import './Feeds.css'

const Feeds = () => (
    <Connect
        query={graphqlOperation(queries.listTransmissions, {startDay:moment().startOf('day').unix()})}
        subscription={graphqlOperation(subscriptions.allNewTransmissions)}
        onSubscriptionMsg={(prev, { newTransmission }) => {
            if (newTransmission.startDay === moment().startOf('day').unix()) {
                prev.listTransmissions.push(newTransmission);
            }
            return prev;
        }}>
        {({ data: {listTransmissions}, loading, error }) => {
            if (error) return (<h3>Error</h3>);
            if (loading || !listTransmissions) return (<h3>Loading...</h3>);
            
            return (<>
                <div className="header">
                    <h2>All of Today's Feeds</h2>
                    <Link className="button cta" to="/feeds/new"><FontAwesomeIcon icon="plus-circle" /> New Feed</Link>
                </div>
                <ul className="feeds">
                    {listTransmissions.sort((a,b) => {
                        if (a.start > b.start) return 1;
                        if (a.start < b.start) return -1;
                        return 0;
                    }).map(tx => (
                        <li key={tx.id} className={((tx.end*1000) < Date.now())?"feed over":"feed"}>
                            <div className="story">
                                IN <Link to={`/candidates/${tx.event.candidate.id}`}>{tx.event.candidate.code} / {tx.event.slug}</Link>
                            </div>
                            <Transmission {...tx} />
                        </li>
                    ))}
                </ul>
            </>);
        }}
    </Connect>
);

export default Feeds;