import React from 'react';
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as queries from '../graphql/queries';

const Lines = (props) => (
    <Connect query={graphqlOperation(queries.listLines)}>
        {({ data, loading, error }) => {
            if (error) return (<strong>Error</strong>);
            if (loading || !data || !data.listLines) return (<strong>Loading...</strong>);

            return <select value={props.selected} onChange={props.onChange}>
                <option value={null}>TBD / Unselected</option>
                {data.listLines.sort((a,b) => {
                    if (a.src > b.src) return 1;
                    if (a.src < b.src) return -1;
                    return 0;
                }).map(line => (
                    <option key={line.id} value={line.id}>
                        {line.src}
                    </option>
                ))}
            </select>
        }}  
    </Connect>
);

export default Lines;