import React, { Component } from 'react';
import DayComponent from './day-component';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Css for WeekComponent:
import './weekComponent.css';

export class WeekComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            week: {
                Sunday: [],
                Monday: [],
                Tuesday: [],
                Wednesday: [],
                Thursday: [],
                Friday: [],
                Saturday: [],

            },
            curDay: moment().startOf('day'),
            curWeek: moment().startOf('week'),
            startDay: moment().startOf('day'),
            dateView: false,
            stateSort: false,
            currentDate: moment().startOf('day'),
            hideNoCoverage: false,
        }
    }   

    render() {
        return(

        <div className="multi-daybook-container">

            <div className="multi-title">
                <h2>Multi-Day Book</h2>
            </div>

            <div className="multi-buttons">
                <Link className="multi-button" to="/events/new">
                    <FontAwesomeIcon icon="calendar-plus" /> New Event
                </Link>
            </div>

            <br />

            <div className="weekContainer">
                <div className="week-header">
                    <button className="multi-button" onClick={() => this.prevDate()}><FontAwesomeIcon icon="chevron-left" size="1x"/> Previous Week</button>

                    <div>
                        <p>Week Of { this.state.curWeek.format('MMMM Do, YYYY').toString() }</p>
                    </div>

                    <button className="multi-button" onClick={() => this.advDate()}>Next Week <FontAwesomeIcon icon="chevron-right" size="1x"/></button>
                </div>
                {
                    Object.keys(this.state.week).map(day => {
                        return <DayComponent key={this.state.curWeek.day(day)} day={day} week={this.state.curWeek} />
                    })
                }
            </div>

        </div>

        )

    }

    copyDay() {
        const text = this.toCopy.current;

        if(document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(text);
            range.select();
        }
        else if(window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(text);
            selection.removeAllRanges();
            selection.addRange(range);
        }
        else {
            console.warn("Could not select text in node: Unsupported browser.");
        }

        document.execCommand("copy");

        alert("Copied to clipboard.");

        if(window.getSelection) {
            if(window.getSelection().empty) {
                // Chrome Browser support
                window.getSelection().empty();
            }
            else if(window.getSelection().removeRange) {
                // Firefox Browser support
                window.getSelection().removeAllRanges();
            }
        }
        else if(document.selection) {
            // IE support?
            document.selection.empty();
        }
    }

    prevDate() {
        this.setState({
            curWeek: moment(this.state.curWeek).startOf('week').subtract(7, 'days')
        })

    }

    advDate() {
        this.setState({
            curWeek: moment(this.state.curWeek).startOf('week').add(7, 'days')
        })

    }

}

export default WeekComponent
