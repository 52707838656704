import React from 'react';
import moment from 'moment';

class StartAndEndTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: moment(props.start).format("YYYY-MM-DD"),
            start: props.start,
            end: props.end
        }
    }3

    updateState(field, e) {
        let {date, start, end} = this.state;
        if (field === "date") {
            date = e.target.value;
            start = moment(`${date}T${moment(start).format("HH:mm")}`).unix() * 1000;
            end = moment(`${date}T${moment(end).format("HH:mm")}`).unix() * 1000;
            // })        
        }
        else if (field === "start") {
            start = moment(`${date}T${e.target.value}`).unix() * 1000;
            end = start + 7200000; // 2 hours
        } 
        else if (field === "end") {
            // lets keep this ISO-8601
            end = moment(`${date}T${e.target.value}`).unix() * 1000;
        }

        this.setState({date, start, end});

        this.props.updateFn(start, end)
    }

    render() {
        return (
            <>
                <input type="date"
                    required
                    defaultValue={this.state.date}
                    min={moment().format("YYYY-MM-DD")} 
                    onChange={(e) => this.updateState('date', e)} />
                
                {" "}&middot;{" "}

                <input type="time" required value={moment(this.state.start).format("HH:mm")} onChange={(e) => this.updateState('start', e)} /> to{' '}
                <input type="time" required value={moment(this.state.end).format("HH:mm")} onChange={(e) => this.updateState('end', e)}/>
            </>
        )
    }

}

export default StartAndEndTime;