// eslint-disable
// this is an auto generated file. This will be overwritten

export const listCandidates = `query ListCandidates($startDay: AWSTimestamp) {
  listCandidates(startDay: $startDay) {
    id
    code
    name
    photo
    events {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    multievents {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    active
  }
}
`;
export const getCandidate = `query GetCandidate($id: ID!, $startDay: AWSTimestamp) {
  getCandidate(id: $id, startDay: $startDay) {
    id
    code
    name
    photo
    events {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    multievents {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    active
  }
}
`;
export const listEvents = `query ListEvents($startDay: AWSTimestamp) {
  listEvents(startDay: $startDay) {
    id
    slug
    start
    end
    candidate {
      id
      code
      name
      photo
      events {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      multievents {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      active
    }
    candidate_id
    candidates
    startDay
    location
    type
    notes
    covering
    transmissions {
      id
      start
      end
      event {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      event_id
      line {
        id
        src
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        display
      }
      desc
      logLink
      liveChannel
      recordings {
        id
        slug
        start
        end
        transmission_id
        transmission {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        gcAdded
      }
      startDay
    }
  }
}
`;
export const getEvent = `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    slug
    start
    end
    candidate {
      id
      code
      name
      photo
      events {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      multievents {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      active
    }
    candidate_id
    candidates
    startDay
    location
    type
    notes
    covering
    transmissions {
      id
      start
      end
      event {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      event_id
      line {
        id
        src
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        display
      }
      desc
      logLink
      liveChannel
      recordings {
        id
        slug
        start
        end
        transmission_id
        transmission {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        gcAdded
      }
      startDay
    }
  }
}
`;
export const listLines = `query ListLines {
  listLines {
    id
    src
    transmissions {
      id
      start
      end
      event {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      event_id
      line {
        id
        src
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        display
      }
      desc
      logLink
      liveChannel
      recordings {
        id
        slug
        start
        end
        transmission_id
        transmission {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        gcAdded
      }
      startDay
    }
    display
  }
}
`;
export const listTransmissions = `query ListTransmissions($startDay: AWSTimestamp!) {
  listTransmissions(startDay: $startDay) {
    id
    start
    end
    event {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    event_id
    line {
      id
      src
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
      display
    }
    desc
    logLink
    liveChannel
    recordings {
      id
      slug
      start
      end
      transmission_id
      transmission {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
      gcAdded
    }
    startDay
  }
}
`;
export const getTransmission = `query GetTransmission($id: ID!) {
  getTransmission(id: $id) {
    id
    start
    end
    event {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    event_id
    line {
      id
      src
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
      display
    }
    desc
    logLink
    liveChannel
    recordings {
      id
      slug
      start
      end
      transmission_id
      transmission {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
      gcAdded
    }
    startDay
  }
}
`;
export const listRecordings = `query ListRecordings {
  listRecordings {
    id
    slug
    start
    end
    transmission_id
    transmission {
      id
      start
      end
      event {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      event_id
      line {
        id
        src
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        display
      }
      desc
      logLink
      liveChannel
      recordings {
        id
        slug
        start
        end
        transmission_id
        transmission {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        gcAdded
      }
      startDay
    }
    gcAdded
  }
}
`;
