import React from 'react';
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { Link } from "react-router-dom";
import './CandidateContainer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as queries from '../graphql/definedQueries';
import * as subscriptions from '../graphql/subscriptions';

const CandidateContainer = (props) => (
    <>
    <div className="header">
        <h2>Candidates</h2>
        <Link to='/candidates/new' className="button cta"><FontAwesomeIcon icon="plus-circle" />  New Candidate</Link>
    </div>
    <ul className="candidates">        
        <Connect
            query={graphqlOperation(queries.listShallowCandidates)}
            subscription={graphqlOperation(subscriptions.newCandidate)}
            onSubscriptionMsg={(prev, { newCandidate }) => {
                prev.listCandidates.push(newCandidate);
                return prev;
            }}>
            {({ data, loading, error }) => {
                if (error) return (<h3>Error</h3>);
                if (loading || !data || !data.listCandidates) return (<h3>Loading...</h3>);
                
                return data.listCandidates.sort((a,b) => {
                    if (a.code > b.code) return 1;
                    if (a.code < b.code) return -1;
                    return 0;
                }).filter(candidate => (candidate.active !== false))
                .map(candidate => (
                    <li key={candidate.id}>
                        <Link to={`/candidates/${candidate.id}`}>
                            <img src={candidate.photo} className="candidatePhoto" alt={candidate.name} />
                            <span className="candidateName">
                                <span className="code">[{candidate.code}]</span> - {candidate.name}
                            </span>
                        </Link>
                    </li>
                ));
            }}
        </Connect>
    </ul>
    </>
)

export default CandidateContainer;