import React, { Component } from 'react';
import { Connect } from 'aws-amplify-react';
import { Link } from "react-router-dom";

import './day-component.css';
import { graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import Switch from "react-switch";

import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/definedSubscriptions';

const usStates = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
}

export class DayComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentDate: moment().startOf('day'),
            componentDay: this.props.week.day(this.props.day).startOf('day'),
            hideNoCoverage: false,
        }

        this.toCopy = React.createRef();
    }

    // This will be used to figure out how to advance a week from the current week:
    // console.log(moment(1579150800).startOf('week').add(14, 'days').format('dddd Do'))
    // Whatever week you want 
    // console.log(moment().startOf('week').add(7,'days').day('Thursday'))
    render() {
        return (
            <div className="day-container">

                <div className="day-header">

                    <div>
                        <p className="day-title">{ this.props.week.day(this.props.day).format('ddd') } { this.props.week.day(this.props.day).format('MMM Do') }</p>
                    </div>

                    <label className="switch">
                        <span>Hide No Coverage</span>                        
                        <Switch onChange={() => this.setState({hideNoCoverage:!this.state.hideNoCoverage})} checked={this.state.hideNoCoverage} height={20} width={40} />
                    </label>

                    <button className="day-button" onClick={() => this.setState({stateSort:!this.state.stateSort})}>Sort by {this.state.stateSort?"Candidate":"State"}</button>


                    <button className="day-button" onClick={() => this.copyDay()}>Copy
                    </button>

                </div>


                <div ref={ this.toCopy }>
                <Connect
                query = {
                    graphqlOperation(
                        queries.listCandidates,
                        {
                            // startDay: moment().startOf('week').day(this.state.componentDay).unix()
                            startDay: this.state.componentDay.unix(),
                        }
                    )
                }
                subscription = {
                    graphqlOperation(subscriptions.allNewEvents)
                }

                onSubscriptionMsg = {
                    (prev, { newEvent }) => {
                        if(moment(newEvent.start * 1000).startOf('day').unix() === this.state.componentDay.unix()) {
                            prev.listCandidates.forEach(candidate => {
                                if(candidate.id === newEvent.candidate.id) {
                                    candidate.events.push(newEvent);
                                }
                            })
                        }

                        return prev;
                    }
                }>

                {({data: { listCandidates }, loading, error}) => {
                    if(error) return(<h3>Error</h3>);
                    if(loading || !listCandidates) return(<h3>Loading...</h3>);

                    if(this.state.stateSort) {
                        return listCandidates.map(candidate => {
                            return candidate.events
                            .filter(event => this.state.hideNoCoverage ? (event.covering && event.covering.toLowerCase() !== "no coverage") : true)
                            .map(event => {
                                return Object.assign({}, event, {
                                    candidate: {id: candidate.id, name:candidate.name},
                                    usState: usStates[(event.location.split(', ')[event.location.split(', ').length-2]).split(' ')[0]] || "[Other]"
                                })
                            })
                        })
                        .flat()
                        .sort((a, b) => {
                            if(a.usState.toString() < b.usState.toString()) return -1;
                            if(a.usState.toString() > b.usState.toString()) return 1;
                            return 0;
                        })
                        .map((event, i, all) => (
                            <React.Fragment key={ event.id }>
                                {((i === 0 || (all[i - 1].usState !== event.usState)) && <>
                                {
                                    i !== 0 && <br />
                                } <strong>{ event.usState }</strong><br />
                                </>)}

                                <Link to={{
                                    pathname: `/candidates/${event.candidate.id}`,
                                    state: {
                                        showDay: moment(event.start * 1000).startOf('day').unix()
                                    }
                                }}>{ moment(event.start * 1000).format('h:mm A') } - { event.candidate.name } - { event.slug }</Link> - { event.location } { event.covering && <>(Coverage: { event.covering })</>} <br />
                            </React.Fragment>
                        ));
                    } else {
                        return listCandidates.map(candidate => {
                            if(candidate.events.filter(event => this.state.hideNoCoverage ? (event.covering && event.covering.toLowerCase() !== "no coverage") : true).length > 0) {
                                return(

                                    <div key={ candidate.name } className="day-body">
                                        <p key={ candidate.name }>
                                            <strong>{ candidate.name }</strong><br />

                                            { candidate.events.filter(event => this.state.hideNoCoverage ? (event.covering && event.covering.toLowerCase() !== "no coverage") : true).sort((a, b) => {
                                                if(a.start > b.start) return 1;
                                                if(a.start < b.start) return -1;
                                                return 0
                                            })
                                            .map(event => (
                                                <React.Fragment key={ event.id }>
                                                    <Link to={{
                                                        pathname: `/candidates/${ candidate.id }`,
                                                    }}>
                                                        { moment(event.start * 1000).format('h:mm a' ) } - { event.slug }
                                                    </Link> 
                                                    - { event.location } { event.covering && <>(Coverage: { event.covering })</> }
                                                    <br />
                                                </React.Fragment>

                                            ))
                                            }
                                        </p> 
                                    </div>
                                )
                            } else {
                                return null
                            }
                        })
                    }
                }}
                </Connect>
                </div>

            </div>
        )
    }

    copyDay() {
        const text = this.toCopy.current;

        if (document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(text);
            range.select();
        } 
        else if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(text);
            selection.removeAllRanges();
            selection.addRange(range);
        } 
        else {
            console.warn("Could not select text in node: Unsupported browser.");
        }

        document.execCommand('copy');

        alert(`Copied to clipboard.`);

        if (window.getSelection) {
            if (window.getSelection().empty) {  // Chrome
              window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {  // Firefox
              window.getSelection().removeAllRanges();
            }
          } else if (document.selection) {  // IE?
            document.selection.empty();
          }
    }
}

export default DayComponent
