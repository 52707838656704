// for subscriptions i want to define myself and not have amplify codegen overwrite

// caveat going forward -- this is required because I need a newTransmission WITHOUT
// an argument passed, so I can get call regardless of event_id.
// HOWEVER -- all data returned is defined in the MUTATION that triggers the event.

export const allNewTransmissions = `subscription allNewTransmissions {
    newTransmission {
        id
        start
        end
        event {
            id
            slug
            start
            end
        }
        line {
            id
            src
        }
        desc
        startDay
    }
}`;

export const allNewEvents = `subscription allNewEvents {
    newEvent {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
      }
      candidate_id
      location
      covering
    }
  }
  `;