import React from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as subscriptions from '../graphql/subscriptions';

class Transmission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            self: this.props,
            recordings: this.props.recordings
        }
    }

    liveChannelURL(channel) {
        const mapped = [
            "abc_live1",
            "abc_live2",
            "abc_live3",
            "abc_live4",
            "abc_live5",
            "abc_live06",
            "abc_live07",
            "abc_live08",
            "abc_live09",
            "abc_live10",
            "abc_live11",
            "abc_live12",
            "abc_live13",
            "abc_live14",
            "abc_live15",
            "abc_live16",
            "abc_live17",
            "abc_live18",
            "abc_live19",
            "abc_live20"
        ];

        return `https://abcnews.go.com/video/portableplayer?id=${mapped[channel-1]}&autoStart=true`;
    }

    componentDidMount() {
        const recSubscription = API.graphql(
            graphqlOperation(subscriptions.newRecording, {transmission_id: this.props.id})
        ).subscribe({
            next: recording => {
                const recordings = this.state.recordings.concat([recording.value.data.newRecording])
                this.setState({recordings})
            }
        });

        const updateSubscription = API.graphql(
            graphqlOperation(subscriptions.updatedTransmission, {id: this.props.id})
        ).subscribe({
            next: tx => {
                const newTx = tx.value.data.updatedTransmission;
                this.setState({self:newTx})
            }
        })

        this.setState({recSubscription, updateSubscription})
    }

    componentWillUnmount() {
        this.state.recSubscription.unsubscribe();
        this.state.updateSubscription.unsubscribe();
    }

    render() {
        const self = this.state.self || this.props;
        return (
            <>
                <div>
                    <FontAwesomeIcon icon="satellite-dish" className="txicon" />
                    <strong>{(self.line)?self.line.src:"TBD"} / {self.desc}</strong> &middot;{" "}
                    
                    <span className="txtime">
                        {moment(self.start * 1000).format("h:mm A")} - {moment(self.end * 1000).format("h:mm A")}
                    </span>
                    { self.logLink && 
                        <>
                        <br />
                        <a href={self.logLink} className="loglink" target="_new">
                            <FontAwesomeIcon icon="file-alt" fixedWidth className="icon" />Log <FontAwesomeIcon className="linkicon" icon="external-link-alt" />
                        </a>
                        </>
                    }
                    { self.liveChannel && 
                        <>
                            <br />
                            <a href={this.liveChannelURL(self.liveChannel)} className="loglink" target="_new">
                                <FontAwesomeIcon icon="broadcast-tower" fixedWidth className="icon" /> Live Channel {self.liveChannel} <FontAwesomeIcon className="linkicon" icon="external-link-alt" />
                            </a>
                        </>
                    }
                    <ul>
                        {this.state.recordings.map(record => (
                            <li key={record.id}>
                                <span className="prefix">IN NY INTERPLAY:</span> <strong>{record.slug}</strong>                        
                            </li>
                        ))}
                    </ul>
                </div>
                <Link to={{
                        pathname:'/feeds/edit',
                        state: {
                            isEdit:true,
                            id: self.id,
                            line_id: self.line?self.line.id:null,
                            event_id: self.event_id || self.event.id,
                            event_slug: self.event_slug || self.event.slug,
                            desc: self.desc,
                            start: self.start*1000,
                            end: self.end*1000,
                            logLink: self.logLink,
                            liveChannel: self.liveChannel
                        }
                    }} className="button">
                    Edit Feed
                </Link>
            </>
        )
    }

}

export default Transmission;