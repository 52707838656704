import React, { Component } from 'react';
import Amplify, {Auth} from 'aws-amplify';
import awsmobile from './aws-exports'; // your Amplify configuration
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import CandidateContainer from './components/CandidateContainer';
import CandidateDetail from './components/CandidateDetail';
import DayBook from './components/DayBook';
import Multidaybook from './components/multidaybook';
import Feeds from './components/Feeds';
import NewEditFeed from './components/NewEditFeed';
import NewEvent from './components/NewEvent';
import NewCandidate from './components/NewCandidate';
import Schedule from './components/Schedule';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faBars,
  faBroadcastTower,
  faBook,
  faClock,
  faCalendarPlus,
  faCaretDown,
  faCaretUp,
  faChevronLeft,
  faChevronRight,
  faEdit,
  faExternalLinkAlt,
  faFileAlt,
  faLandmark,
  faPlusCircle,
  faSatelliteDish,
  faStickyNote,
  faTimesCircle,
  faMapMarkerAlt,
  faVideo 
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faBars,
  faBroadcastTower,
  faBook,
  faClock,
  faCalendarPlus,
  faCaretDown,
  faCaretUp,
  faChevronLeft,
  faChevronRight,
  faEdit,
  faExternalLinkAlt,
  faFileAlt,
  faLandmark,
  faPlusCircle,
  faSatelliteDish,
  faStickyNote,
  faTimesCircle,
  faMapMarkerAlt,
  faVideo
);

// your Cognito Hosted UI configuration
const oauth = {
  domain: process.env.REACT_APP_COGNITO_DOMAIN,
  scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT,
  redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT,
  responseType: 'code' // or token
};

awsmobile.aws_appsync_graphqlEndpoint = process.env.REACT_APP_GRAPHQL;

const config = Object.assign({}, awsmobile, {Auth: {
  oauth,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  identityPoolRegion: 'us-east-1'
}})

Amplify.configure(config)

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showUserDropDown: false,
      mobileMenu: false
    }
  }

  userName() {
    const { authData } = this.props;
    let firstname, lastname;
    JSON.parse(authData.storage[authData.userDataKey]).UserAttributes.forEach(attr => {
      if (attr.Name === "given_name")
        firstname = attr.Value
      else if (attr.Name === "family_name")
        lastname = attr.Value
    })
    return `${firstname} ${lastname}`;
  }

  render() {
    const { authState } = this.props;
    // work around to get env -- https://github.com/aws-amplify/amplify-cli/issues/672#issuecomment-452952957
    
    return (
        <Router>
          <h1>{process.env.REACT_APP_DOMAIN}</h1>
          <div className="gctApp">
            {authState === 'loading' && (<div>loading...</div>)}
            {authState === 'signIn' &&
              <>
                <h1 className="loginHeader">Login</h1>
                <div className="login">
                    <a href={process.env.REACT_APP_LOGIN_LINK}
                            className="button cta">
                      Sign in with MyID
                    </a>            
                </div>
              </>
            }
              {authState === 'signedIn' && (<>
                <div className="topNav">
                  <h1><Link to='/'>Grand Central</Link></h1>
                  { Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 1000 &&
                    <ul>
                      <li><Link to="/">Candidates</Link></li>
                      <li><Link to="/feeds">Feeds</Link></li>
                      <li><Link to="/day">Day Book</Link></li>
                      <li><Link to="/week">Week View</Link></li>
                      <li><Link to="/schedule/lines">Line Schedule</Link></li>
                      <li><Link to="/schedule/candidate">Candidate Schedule</Link></li>
                      <li className="userbtn"><button onClick={() => this.setState({showUserDropDown:!this.state.showUserDropDown})}>{this.userName()} <FontAwesomeIcon icon="caret-down" /></button>
                        {this.state && this.state.showUserDropDown && 
                          <ul>
                            <li><button onClick={() => Auth.signOut()}>Sign Out</button></li>
                          </ul>
                        }
                      </li>
                    </ul>
                  }
                  { Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <= 1000 && 
                    <>
                      <button className="mobileMenu" onClick={() => this.setState({mobileMenu: !this.state.mobileMenu})}>
                        <FontAwesomeIcon icon="bars" /> Menu
                      </button>
                      { this.state.mobileMenu && 
                        <ul className="mobileMenuOpen">
                          <li><Link to="/">Candidates</Link></li>
                          <li><Link to="/feeds">Feeds</Link></li>
                          <li><Link to="/day">Day Book</Link></li>
                          <li><Link to="/week">Week View</Link></li>
                          <li><Link to="/schedule/lines">Line Schedule</Link></li>
                          <li><Link to="/schedule/candidate">Candidate Schedule</Link></li>
                          <li className="userbtn"><button onClick={() => this.setState({showUserDropDown:!this.state.showUserDropDown})}>{this.userName()} <FontAwesomeIcon icon="caret-down" /></button>
                            {this.state && this.state.showUserDropDown && 
                              <ul>
                                <li><button onClick={() => Auth.signOut()}>Sign Out</button></li>
                              </ul>
                            }
                          </li>
                        </ul>
                      }
                    </>
                  }
                </div>
                <div className="App">                  
                    <Switch>
                      <Route path="/" exact component={CandidateContainer} />        
                      <Route path="/candidates/new" exact component={NewCandidate} />       
                      <Route path="/candidates/:id" exact component={CandidateDetail} />
                      <Route path="/day" exact component={DayBook} />
                      <Route path="/week" exact component={Multidaybook} />
                      <Route path="/events/new" exact component={NewEvent} />                      
                      <Route path="/feeds" exact component={Feeds} />
                      <Route path="/feeds/new" exact component={NewEditFeed} />
                      <Route path="/feeds/edit" exact component={NewEditFeed} /> 
                      <Route path="/schedule/:method" exact component={Schedule} />
                    </Switch>
                  </div>
              </>)}
          </div>
        </Router>
    );
  }
}

export default App;
