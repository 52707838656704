// eslint-disable
// this is an auto generated file. This will be overwritten

export const createTransmission = `mutation CreateTransmission(
  $event_id: ID!
  $line_id: ID
  $desc: String
  $logLink: AWSURL
  $liveChannel: Int
  $start: AWSTimestamp!
  $end: AWSTimestamp!
  $startDay: AWSTimestamp!
) {
  createTransmission(
    event_id: $event_id
    line_id: $line_id
    desc: $desc
    logLink: $logLink
    liveChannel: $liveChannel
    start: $start
    end: $end
    startDay: $startDay
  ) {
    id
    start
    end
    event {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    event_id
    line {
      id
      src
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
      display
    }
    desc
    logLink
    liveChannel
    recordings {
      id
      slug
      start
      end
      transmission_id
      transmission {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
      gcAdded
    }
    startDay
  }
}
`;
export const createRecording = `mutation CreateRecording(
  $id: ID!
  $slug: String!
  $start: AWSTimestamp!
  $end: AWSTimestamp!
  $transmission_id: ID
  $gcAdded: Boolean!
) {
  createRecording(
    id: $id
    slug: $slug
    start: $start
    end: $end
    transmission_id: $transmission_id
    gcAdded: $gcAdded
  ) {
    id
    slug
    start
    end
    transmission_id
    transmission {
      id
      start
      end
      event {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      event_id
      line {
        id
        src
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        display
      }
      desc
      logLink
      liveChannel
      recordings {
        id
        slug
        start
        end
        transmission_id
        transmission {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        gcAdded
      }
      startDay
    }
    gcAdded
  }
}
`;
export const createEvent = `mutation CreateEvent(
  $slug: String
  $start: AWSTimestamp
  $end: AWSTimestamp
  $location: String
  $covering: String
  $type: String
  $notes: String
  $candidate_id: ID!
  $candidates: [ID]
  $startDay: AWSTimestamp
) {
  createEvent(
    slug: $slug
    start: $start
    end: $end
    location: $location
    covering: $covering
    type: $type
    notes: $notes
    candidate_id: $candidate_id
    candidates: $candidates
    startDay: $startDay
  ) {
    id
    slug
    start
    end
    candidate {
      id
      code
      name
      photo
      events {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      multievents {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      active
    }
    candidate_id
    candidates
    startDay
    location
    type
    notes
    covering
    transmissions {
      id
      start
      end
      event {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      event_id
      line {
        id
        src
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        display
      }
      desc
      logLink
      liveChannel
      recordings {
        id
        slug
        start
        end
        transmission_id
        transmission {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        gcAdded
      }
      startDay
    }
  }
}
`;
export const updateEvent = `mutation UpdateEvent(
  $id: ID!
  $slug: String
  $start: AWSTimestamp
  $end: AWSTimestamp
  $location: String
  $covering: String
  $type: String
  $notes: String
  $startDay: AWSTimestamp
) {
  updateEvent(
    id: $id
    slug: $slug
    start: $start
    end: $end
    location: $location
    covering: $covering
    type: $type
    notes: $notes
    startDay: $startDay
  ) {
    id
    slug
    start
    end
    candidate {
      id
      code
      name
      photo
      events {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      multievents {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      active
    }
    candidate_id
    candidates
    startDay
    location
    type
    notes
    covering
    transmissions {
      id
      start
      end
      event {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      event_id
      line {
        id
        src
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        display
      }
      desc
      logLink
      liveChannel
      recordings {
        id
        slug
        start
        end
        transmission_id
        transmission {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        gcAdded
      }
      startDay
    }
  }
}
`;
export const deleteEvent = `mutation DeleteEvent($id: ID!) {
  deleteEvent(id: $id)
}
`;
export const updateTransmission = `mutation UpdateTransmission(
  $id: ID!
  $line_id: ID
  $desc: String
  $logLink: AWSURL
  $liveChannel: Int
  $start: AWSTimestamp!
  $end: AWSTimestamp!
  $startDay: AWSTimestamp!
) {
  updateTransmission(
    id: $id
    line_id: $line_id
    desc: $desc
    logLink: $logLink
    liveChannel: $liveChannel
    start: $start
    end: $end
    startDay: $startDay
  ) {
    id
    start
    end
    event {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    event_id
    line {
      id
      src
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
      display
    }
    desc
    logLink
    liveChannel
    recordings {
      id
      slug
      start
      end
      transmission_id
      transmission {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
      gcAdded
    }
    startDay
  }
}
`;
export const createCandidate = `mutation CreateCandidate($name: String!, $code: String!, $photo: String) {
  createCandidate(name: $name, code: $code, photo: $photo) {
    id
    code
    name
    photo
    events {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    multievents {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    active
  }
}
`;
