// for queries i want to define myself and not have amplify codegen overwrite

export const listShallowCandidates = `query ListShallowCandidates {
  listCandidates {
    id,
    code
    name
    photo
    active
  }
}`

export const getShallowCandidate = `query GetShallowCandidate($id: ID!) {
  getCandidate(id: $id) {
    id
    code
    name
    photo
  }
}`;

export const getFullCandidate = `query GetFullCandidate($id: ID! $startDay: AWSTimestamp) {
    getCandidate(id: $id, startDay: $startDay) {
      id
      code
      name
      photo
      events {
        id
        candidates
        slug
        start
        end        
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          desc
          liveChannel
          logLink
          line {
              id,
              src
          },
          recordings {
              id
              slug
          }
        }
      }
      multievents {
        id
        candidates
        slug
        start
        end        
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          desc
          liveChannel
          logLink
          line {
              id,
              src
          },
          recordings {
              id
              slug
          }
        }
      }
    }
  }
  `;