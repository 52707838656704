import React from 'react';

const range = () => {
    let arr = [];
    const LIVE_CHANNELS = 20;
    for (let i = 0; i<LIVE_CHANNELS; i++) {
        arr.push(i+1);
    }
    return arr;
}
const LiveChannels = (props) => (
    <select value={props.selected} onChange={props.onChange}>
        <option value={false}>TBD / Unselected</option>
        { range().map(live => (
            <option key={live} value={live}>
                Live Channel {live}
            </option>
        ))}
    </select>
);

export default LiveChannels;