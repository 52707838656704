import React from "react";
import { Authenticator, SignIn, Greetings } from 'aws-amplify-react';
import config from "./aws-exports";
import App from "./App";


class AppWithAuth extends React.Component {
    render () {
        return (
            <Authenticator hide={[SignIn, Greetings]} amplifyConfig={config}>
                <App />
            </Authenticator>
        );
    }
}

export default AppWithAuth;