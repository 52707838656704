import React from 'react';
import Lines from './Lines';
import LiveChannels from './LiveChannels';
import EventMenu from './EventMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StartAndEndTime from './StartAndEndTime';
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as mutations from '../graphql/mutations';
import moment from 'moment';

const expected = ["event_id", "desc", "start", "end"];
const optional = ["line_id", "logLink", "liveChannel"];

class NewEditFeed extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.props.location.state || {};
        this.state.valid = {};
        // this.state.line_id = "tbd"; // handle TBD lines
        this.updateTimes = this.updateTimes.bind(this);
    }

    componentDidMount() {
        let valid = this.state.valid;
        expected.concat(optional).forEach(field => {
            if (this.state[field] && (Number.isInteger(this.state[field]) || this.state[field].trim() !== '')) {
                valid[field] = true;
            }
            else {
                console.log(field, this.state[field])
            }
        })
        this.setState({valid})
    }

    updateTimes(start, end) {
        let valid = this.state.valid;
        if ((start > Date.now()) && (end > start)) {
            valid.start = true;
            valid.end = true;
            this.setState({
                start,
                end,
                valid
            });
        }
        else {
            valid.start = false;
            valid.end = false;
            this.setState({
                start: null,
                end: null,
                valid
            })
        }
    }

    updateState(field, e) {
        let valid = this.state.valid;
        if (e.target.value.trim() === '' || e.target.value === "false") {            
            valid[field] = false;
        }
        else {
            valid[field] = true;
        }
        this.setState({
            valid,
            [field]:e.target.value
        });
    }

    isEnabled() {        
        const hasInvalid = expected.some(field => {
            return !this.state.valid[field];
        })

        return !hasInvalid
    }

    hasError(field) {
        return (this.state.valid[field] === false)
    }

    render() {
        const {start, end, event_id, line_id, liveChannel} = this.state || {};
        const isEnabled = this.isEnabled();
        const editing = this.state.isEdit;
        const gqlMutation = editing?mutations.updateTransmission:mutations.createTransmission;

        return (
            <> 
                <h2><FontAwesomeIcon icon="satellite-dish" /> {editing?"Editing":"New"} Feed</h2>
                <div className="form">
                    <Connect mutation={graphqlOperation(gqlMutation)}>
                        {({mutation}) => (
                            <>
                            <p className={this.hasError('line_id')?"formRow error":"formRow"}>
                                <label>Line:</label>
                                <Lines selected={line_id} onChange={(e) => this.updateState('line_id', e)} />
                            </p>
                            {!editing && 
                                <p className={this.hasError('event_id')?"formRow error reqd":"formRow reqd"}>
                                    <label>Event:</label>
                                    <EventMenu selected={event_id} onChange={(e) => this.updateState('event_id', e)} />
                                </p>
                            }
                            {editing && 
                                <p className="formRow">
                                    <label>Event:</label>
                                    <span className="formFiller">{this.state.event_slug}</span>
                                </p>
                            }
                            <p className={this.hasError('desc')?"formRow reqd error":"formRow reqd"}>
                                <FontAwesomeIcon icon="sticky-note" fixedWidth  className="icon" />
                                <input type="text" placeholder="Description" onChange={(e) => this.updateState('desc', e)} />
                            </p>
                            <p className={this.hasError('start')?"formRow error":"formRow"}>
                                <FontAwesomeIcon icon="clock" fixedWidth className="icon" />
                                <StartAndEndTime start={start} end={end} updateFn={this.updateTimes} />
                            </p>
                            <p className={this.hasError('logLink')?"formRow error":"formRow"}>
                                <FontAwesomeIcon icon="file-alt" fixedWidth className="icon" />
                                <input type="text" placeholder="Link to Log" onChange={(e) => this.updateState('logLink', e)} />
                            </p>
                            <p className={this.hasError('liveChannel')?"formRow errr":"formRow"}>
                                <FontAwesomeIcon icon="broadcast-tower" fixedWidth className="icon" />
                                <label>Live Channel:</label>
                                <LiveChannels selected={liveChannel} onChange={(e) => this.updateState('liveChannel', e)} />
                            </p>
                            <button className="cta" disabled={!isEnabled} onClick={async () => {
                                console.log(this.state)
                                if (this.state.isEdit) {
                                    // we're updating an existing transmission
                                    const updateFeed = await mutation({
                                        id: this.state.id,
                                        line_id: this.state.line_id || "TBD", // need something because line_id is an index                                        
                                        desc: this.state.desc,
                                        logLink: this.state.logLink,
                                        liveChannel: this.state.liveChannel,
                                        start: this.state.start/1000,
                                        end: this.state.end/1000,
                                        startDay: moment(this.state.start).startOf('day').unix()
                                    });
                                    this.props.history.push(`/candidates/${updateFeed.data.updateTransmission.event.candidate.id}`)
                                }
                                else {
                                    // doesnt exist, we need to create
                                    const newFeed = await mutation({
                                        line_id: this.state.line_id || "TBD", // need something because line_id is an index
                                        event_id: this.state.event_id,
                                        desc: this.state.desc,
                                        logLink: this.state.logLink,
                                        liveChannel: this.state.liveChannel,
                                        start: this.state.start/1000,
                                        end: this.state.end/1000,
                                        startDay: moment(this.state.start).startOf('day').unix()
                                    });
                                    this.props.history.push(`/candidates/${newFeed.data.createTransmission.event.candidate.id}`)
                                }
                            }}>
                                <FontAwesomeIcon icon="plus-circle" /> {editing?"Update":"Create"} Feed
                            </button>
                            </>
                        )}
                    </Connect>
                </div>
            </>
        );
    }

}
export default NewEditFeed;