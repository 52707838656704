import React from 'react';
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Switch from "react-switch";
import moment from 'moment';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/definedSubscriptions';

import './DayBook.css';

const usStates = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
}

class DayBook extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateView:false,
            stateSort: false,
            currentDate: moment().startOf('day'),
            hideNoCoverage: false
        }
        this.toCopy = React.createRef();
    }

    setDate(e) {
        this.setState({
            dateView:false,
            currentDate:moment(e.target.value).startOf('day')
        })
    }

    copyDayBook() {
        const text = this.toCopy.current;
        
        if (document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(text);
            range.select();
        } else if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(text);
            selection.removeAllRanges();
            selection.addRange(range);
        } else {
            console.warn("Could not select text in node: Unsupported browser.");
        }

        document.execCommand('copy');

        alert("Copied to clipboard.");

        if (window.getSelection) {
            if (window.getSelection().empty) {  // Chrome
              window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {  // Firefox
              window.getSelection().removeAllRanges();
            }
          } else if (document.selection) {  // IE?
            document.selection.empty();
          }
    }


    render () {
        return (<>
            <h2>
                Day Book
            </h2>
            <div className="header">
                <div>
                    {!this.state.dateView && 
                        <button className="asLink" onClick={() => this.setState({dateView:true})}>
                            Showing {this.state.currentDate.format('MM/DD/YYYY')}&nbsp;
                            <FontAwesomeIcon icon="caret-down" />
                        </button>
                    }
                    {this.state.dateView && 
                        <input type="date" defaultValue={this.state.currentDate.format('YYYY-MM-DD')} onBlur={e=>this.setDate(e)} onKeyDown={e=>{if (e.keyCode === 13) {this.setDate(e)}}}/>
                    }
                    <br/>
                    <button className="asLink" onClick={() => this.setState({
                        dateView: false,
                        currentDate: moment().add(1, 'days').startOf('day')
                    })}>Go to Tomorrow</button>                 
                </div>
                <Link className="button cta" to="/events/new">
                    <FontAwesomeIcon icon="calendar-plus" /> New Event
                </Link>
            </div>
            <div className="dayBookWrap">
                <button className="dbNav" onClick={() => this.setState({
                        dateView: false,
                        currentDate: moment(this.state.currentDate).subtract(1, 'days').startOf('day')
                    })}>
                    <FontAwesomeIcon icon="chevron-left" size="2x"/>
                </button>
                <div className="toPrint">
                    <div className="header">
                        <button onClick={() => this.copyDayBook()}>Copy Day Book</button>
                        <label className="switch">
                            <span>Hide No Coverage</span>                        
                            <Switch onChange={() => this.setState({hideNoCoverage:!this.state.hideNoCoverage})} checked={this.state.hideNoCoverage} height={20} width={40} />
                        </label>
                        <button onClick={() => this.setState({stateSort:!this.state.stateSort})}>Sort by {this.state.stateSort?"Candidate":"State"}</button>
                    </div>
                    <div ref={this.toCopy}>
                    <h3>{this.state.currentDate.format('dddd, MMMM Do')}</h3>
                    <Connect
                        query={graphqlOperation(queries.listCandidates, {startDay: moment(this.state.currentDate).startOf('day').unix()})}
                        subscription={graphqlOperation(subscriptions.allNewEvents)}
                        onSubscriptionMsg={(prev, {newEvent}) => {
                            if (moment(newEvent.start * 1000).startOf('day').unix() === moment().startOf('day').unix()) {
                                prev.listCandidates.forEach(candidate => {
                                    if (candidate.id === newEvent.candidate.id) {
                                        candidate.events.push(newEvent);
                                    }
                                })
                            }
                            return prev;
                        }}>
                        {({ data: {listCandidates}, loading, error }) => {
                            if (error) return (<h3>Error</h3>);
                            if (loading || !listCandidates) return (<h3>Loading...</h3>);
                            
                            if (this.state.stateSort) {
                                return listCandidates.map(candidate => {
                                    return candidate.events
                                        .filter(event => this.state.hideNoCoverage?(event.covering && event.covering.toLowerCase()!=="no coverage"):true)
                                        .map(event=> {
                                            return Object.assign({}, event, {
                                                candidate: {id: candidate.id, name:candidate.name},
                                                usState: usStates[(event.location.split(', ')[event.location.split(', ').length-2]).split(' ')[0]] || "[Other]"
                                            })
                                    })
                                })
                                .flat()
                                .sort((a,b) => {
                                    if (a.usState.toString() < b.usState.toString()) return -1;
                                    if (a.usState.toString() > b.usState.toString()) return 1;
                                    return 0;
                                }).map((event, i, all) => (
                                    <React.Fragment key={event.id}>
                                        {((i === 0) || (all[i-1].usState !== event.usState)) && <>
                                            {i !== 0 &&
                                                <br/>
                                            }<strong>{event.usState}</strong><br/>
                                        </>}
                                        <Link to={{
                                            pathname: `/candidates/${event.candidate.id}`,
                                            state: {
                                                showDay: moment(event.start*1000).startOf('day').unix()
                                            }
                                        }}>{moment(event.start*1000).format('h:mm A')} - {event.candidate.name} - {event.slug}</Link> - {event.location} {event.covering && <>(Coverage: {event.covering})</>}<br/>
                                        
                                    </React.Fragment>
                                ));

                            }
                            else {
                                return listCandidates.map(candidate => {
                                    if (candidate.events.filter(event => this.state.hideNoCoverage?(event.covering && event.covering.toLowerCase()!=="no coverage"):true).length > 0) {
                                        return (
                                            <p key={candidate.id}>
                                                <strong>{candidate.name}</strong><br/>
                                                {candidate.events.filter(event => this.state.hideNoCoverage?(event.covering && event.covering.toLowerCase()!=="no coverage"):true).sort((a,b) => {
                                                    if (a.start > b.start) return 1;
                                                    if (a.start < b.start) return -1;
                                                    return 0;
                                                }).map(event => (
                                                    <React.Fragment key={event.id}>
                                                        <Link to={{
                                                            pathname: `/candidates/${candidate.id}`,
                                                            state: {
                                                                showDay: moment(event.start*1000).startOf('day').unix()
                                                            }
                                                        }}>{moment(event.start*1000).format('h:mm A')} - {event.slug}</Link> - {event.location} {event.covering && <>(Coverage: {event.covering})</>}<br/>
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                        )
                                    } 
                                    else {
                                        return null;
                                    }
                                })
                            }
                        }}
                    </Connect>
                    </div>
                </div>
                <button  className="dbNav" onClick={() => this.setState({
                        dateView: false,
                        currentDate: moment(this.state.currentDate).add(1, 'days').startOf('day')
                    })}>
                    <FontAwesomeIcon icon="chevron-right" size="2x"/>
                </button>
            </div>
        </>)
    }
}

export default DayBook;