import React from 'react';
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as queries from '../graphql/definedQueries';

const CandidateMenu = (props) => (
    <Connect query={graphqlOperation(queries.listShallowCandidates)}>
        {({ data, loading, error }) => {
            if (error) return (<strong>Error</strong>);
            if (loading || !data || !data.listCandidates) return (<strong>Loading...</strong>);
            
            return <select value={props.selected} onChange={props.onChange}>
                <option value={false}>Candidates</option>
                {data.listCandidates.filter(c => c.id !== 'mult').sort((a,b) => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                }).map(candidate => (
                    <option key={candidate.id} value={candidate.id}>
                        {candidate.name}
                    </option>
                ))}
            </select>
        }}  
    </Connect>
);

export default CandidateMenu;