// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://nvnnnmb3q5anzc2ojq4eecox4y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_content_delivery_bucket": "abcgct",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d268zqswmq2x91.cloudfront.net"
};


export default awsmobile;
