import React from 'react';
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import Event from './Event';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import * as queries from '../graphql/definedQueries';
import * as subscriptions from '../graphql/subscriptions';

import './CandidateDetail.css';

class CandidateContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateView:false,
            currentDate: (this.props.location.state && this.props.location.state.showDay)?moment(this.props.location.state.showDay*1000):moment().startOf('day')
        }
    }
    
    setDate(e) {
        // console.log(e.target.value)
        this.setState({
            dateView:false,
            currentDate:moment(e.target.value).startOf('day')
        })
    }

    render() {
        return (<>
            <Connect
                query={graphqlOperation(queries.getFullCandidate, {id:this.props.match.params.id, startDay:this.state.currentDate.unix()})}
                subscription={graphqlOperation(subscriptions.newEvent, {candidate_id:this.props.match.params.id})}
                onSubscriptionMsg={(prev, { newEvent }) => {
                    let idx = -1;
                    prev.getCandidate.events.forEach((event, i) => {
                        if (event.id === newEvent.id) {
                            idx = i;
                        }
                    });
                    if (idx >= 0) {
                        prev.getCandidate.events.splice(idx,1,newEvent);
                    }
                    else {
                        prev.getCandidate.events.push(newEvent);
                    }
                    return prev;
                }}>
                {({ data: {getCandidate}, loading, error }) => {
                    if (error) return (<h3>Error</h3>);
                    if (loading || !getCandidate) return (<h3>Loading...</h3>);
                    
                    return (<>                        
                        <h2>
                            <img src={getCandidate.photo} className="candidatePhoto" alt={getCandidate.name} />
                            {getCandidate.name} <span className="code">[{getCandidate.code}]</span>
                        </h2>
                        <div className="header">
                            <div>
                                {!this.state.dateView && 
                                    <button className="asLink" onClick={() => this.setState({dateView:true})}>
                                        Showing {this.state.currentDate.format('MM/DD/YYYY')}&nbsp;
                                        <FontAwesomeIcon icon="caret-down" />
                                    </button>
                                }
                                {this.state.dateView && 
                                    <input type="date" defaultValue={this.state.currentDate.format('YYYY-MM-DD')} onBlur={e=>this.setDate(e)} onKeyDown={e=>{if (e.keyCode === 13) {this.setDate(e)}}}/>
                                }                 
                            </div>
                            <Link className="button cta" to={
                                {
                                    pathname:`/events/new`,
                                    state: {
                                        candidates: [this.props.match.params.id]
                                    }
                                }
                                }>
                                <FontAwesomeIcon icon="calendar-plus" /> New Event
                            </Link>
                        </div>
                        <ul className="events">
                            {[...getCandidate.events, ...getCandidate.multievents].sort((a,b) => {
                                if (a.start > b.start) return 1;
                                if (a.start < b.start) return -1;
                                return 0;
                            }).map(event => (
                                <Event {...event} key={event.id + Date.now()} />
                            ))}
                            {([...getCandidate.events, ...getCandidate.multievents].length === 0) && 
                                <span>No {getCandidate.name} events found on {this.state.currentDate.format('MM/DD/YYYY')}.</span>
                            }
                        </ul>
                    </>);
                }}
            </Connect>
        </>);
    }    
}

export default CandidateContainer;