import React, { Component } from 'react';
import moment from 'moment';


import WeekComponent from './weekComponent';

export class Multidaybook extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateView: false,
            stateSort: false,
            currentDate: moment().startOf('day'),
            hideNoCoverage: false,

        }
    }


    render() {

        return (
            <div className="multi-container">
                <WeekComponent curWeek={ moment().startOf('week') } />
            </div>
        )
    }

    //~~~~ Custom Functions:
    setDate(e) {
        this.setState({
            dateView: false,
            currentDate: moment(e.target.value).startOf('day'),
        });
    }

    copyDayBook() {
        const text = this.toCopy.current;

        if(document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(text);
            range.select();
        }
        else if(window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(text);
            selection.removeAllRanges();
            selection.addRange(range);
        }
        else {
            console.warn("Could not select text in node: Unsupported browser.");
        }

        document.execCommand("copy");

        alert("Copied to clipboard.");

        if(window.getSelection) {
            if(window.getSelection().empty) {
                // Chrome Browser support
                window.getSelection().empty();
            }
            else if(window.getSelection().removeRange) {
                // Firefox Browser support
                window.getSelection().removeAllRanges();
            }
        }
        else if(document.selection) {
            // IE support?
            document.selection.empty();
        }
    }
}

export default Multidaybook;
