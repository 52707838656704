// eslint-disable
// this is an auto generated file. This will be overwritten

export const newCandidate = `subscription NewCandidate {
  newCandidate {
    id
    code
    name
    photo
    events {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    multievents {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    active
  }
}
`;
export const newEvent = `subscription NewEvent($candidate_id: ID) {
  newEvent(candidate_id: $candidate_id) {
    id
    slug
    start
    end
    candidate {
      id
      code
      name
      photo
      events {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      multievents {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      active
    }
    candidate_id
    candidates
    startDay
    location
    type
    notes
    covering
    transmissions {
      id
      start
      end
      event {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      event_id
      line {
        id
        src
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        display
      }
      desc
      logLink
      liveChannel
      recordings {
        id
        slug
        start
        end
        transmission_id
        transmission {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        gcAdded
      }
      startDay
    }
  }
}
`;
export const newTransmission = `subscription NewTransmission($event_id: ID) {
  newTransmission(event_id: $event_id) {
    id
    start
    end
    event {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    event_id
    line {
      id
      src
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
      display
    }
    desc
    logLink
    liveChannel
    recordings {
      id
      slug
      start
      end
      transmission_id
      transmission {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
      gcAdded
    }
    startDay
  }
}
`;
export const updatedTransmission = `subscription UpdatedTransmission($id: ID!) {
  updatedTransmission(id: $id) {
    id
    start
    end
    event {
      id
      slug
      start
      end
      candidate {
        id
        code
        name
        photo
        events {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        multievents {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        active
      }
      candidate_id
      candidates
      startDay
      location
      type
      notes
      covering
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
    }
    event_id
    line {
      id
      src
      transmissions {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
      display
    }
    desc
    logLink
    liveChannel
    recordings {
      id
      slug
      start
      end
      transmission_id
      transmission {
        id
        start
        end
        event {
          id
          slug
          start
          end
          candidate_id
          candidates
          startDay
          location
          type
          notes
          covering
        }
        event_id
        line {
          id
          src
          display
        }
        desc
        logLink
        liveChannel
        recordings {
          id
          slug
          start
          end
          transmission_id
          gcAdded
        }
        startDay
      }
      gcAdded
    }
    startDay
  }
}
`;
export const newRecording = `subscription NewRecording($transmission_id: ID) {
  newRecording(transmission_id: $transmission_id) {
    id
    slug
    start
    end
    transmission_id
    transmission {
      id
      start
      end
      event {
        id
        slug
        start
        end
        candidate {
          id
          code
          name
          photo
          active
        }
        candidate_id
        candidates
        startDay
        location
        type
        notes
        covering
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
      }
      event_id
      line {
        id
        src
        transmissions {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        display
      }
      desc
      logLink
      liveChannel
      recordings {
        id
        slug
        start
        end
        transmission_id
        transmission {
          id
          start
          end
          event_id
          desc
          logLink
          liveChannel
          startDay
        }
        gcAdded
      }
      startDay
    }
    gcAdded
  }
}
`;
