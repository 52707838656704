import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as mutations from '../graphql/mutations';

const expected = ["name", "code", "photo"];

class NewStory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: {}
        }
    }

    updateState(field, e) {
        let valid = this.state.valid;
        if (e.target.value.trim() === '' || e.target.value === "false") {
            valid[field] = false;
        }
        else {
            valid[field] = true;
        }
        this.setState({
            valid,
            [field]:e.target.value
        });
    }

    isEnabled() {        
        const hasInvalid = expected.some(field => {
            return !this.state.valid[field];
        })
        
        return !hasInvalid
    }

    hasError(field) {
        return (this.state.valid[field] === false)
    }

    render() {
        const isEnabled = this.isEnabled();
        return (<>
            <h2>New Candidate</h2>
            <div className="form">
            <Connect mutation={graphqlOperation(mutations.createCandidate)}>
                        {({mutation }) => (
                            <>
                                <p className={this.hasError('name')?"formRow error reqd":"formRow reqd"}>
                                    <input type="text" placeholder="Name" onChange={e => this.updateState('name', e)} />
                                </p>
                                <p className={this.hasError('code')?"formRow error reqd":"formRow reqd"}>
                                    <input type="text" placeholder="Short Code" onChange={e => this.updateState('code', e)} />
                                </p>
                                <p className={this.hasError('photo')?"formRow error reqd":"formRow reqd"}>
                                    <input type="text" placeholder="Photo URL" onChange={e => this.updateState('photo', e)} />
                                </p>
                                <button className="cta" disabled={!isEnabled} onClick={async () => {
                                    await mutation({
                                        name: this.state.name,
                                        code: this.state.code,
                                        photo: this.state.photo
                                    });
                                    this.props.history.push('/')
                                }}>
                                    <FontAwesomeIcon icon="plus-circle" /> Create Candidate
                                </button>
                            </>
                        )}
                    </Connect>
            </div>
        </>);
    }
}

export default NewStory;

