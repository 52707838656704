import React from 'react';
import moment from 'moment';
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as queries from '../graphql/queries';

const EventMenu = (props) => (
    <Connect query={graphqlOperation(queries.listEvents, {startDay:moment().startOf('day').unix()})}>
        {({ data, loading, error }) => {
            if (error) return (<strong>Error</strong>);
            if (loading || !data || !data.listEvents) return (<strong>Loading...</strong>);
            
            return <select value={props.selected} onChange={props.onChange}>
                <option value={null}>Events</option>
                {data.listEvents.sort((a,b) => {
                    if (a.candidate.slug > b.candidate.slug) return 1;
                    if (a.candidate.slug < b.candidate.slug) return -1;
                    return 0;
                }).map(event => (
                    <option key={event.id} value={event.id}>
                        [{event.candidate.code}] - {event.slug}
                    </option>
                ))}
            </select>
        }}  
    </Connect>
);

export default EventMenu;