import React from 'react';
import { graphqlOperation, API} from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PlacesAutocomplete from 'react-places-autocomplete';
import moment from 'moment';
import Transmission from './Transmission';
import { Link } from "react-router-dom";
import * as mutations from '../graphql/mutations';
import { getShallowCandidate } from '../graphql/definedQueries';
import * as subscriptions from '../graphql/subscriptions';
import StartAndEndTime from './StartAndEndTime';

class Event extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: {transmissions:props.transmissions},
            editing: false,
            showNote: false,
            slug: props.slug,
            start: props.start * 1000,
            end: props.end * 1000,
            location: props.location,
            address: props.location,
            notes: props.notes,
            covering: props.covering,
            deleted:false
        }
        this.updateTimes = this.updateTimes.bind(this)
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleLocationSelect = this.handleLocationSelect.bind(this);
    }

    handleLocationChange(e) {
        this.setState({address:e})
    }

    handleLocationSelect(e) {
        this.setState({address:e, location:e})
    }

    handleLocationBlur(suggestions) {
        if (suggestions.length > 0) {
            this.setState({address:suggestions[0].description})
            this.updateState('location', suggestions[0].description)
        }
    }

    updateState(field, e) {
        this.setState({[field]: e.target.value })
    }

    updateTimes(start, end) {
        console.log(moment(start).format("HH:mm"), moment(end).format("HH:mm"))
        this.setState({
            start,
            end
        })
    }

    componentDidMount() {
        API.graphql(graphqlOperation(subscriptions.newTransmission)).subscribe({
            next: (data) => {
                console.log(data.value.data)
                const newTransmission = data.value.data.newTransmission;

                let existingState = this.state.event;

                existingState.transmissions.push(newTransmission);

                this.setState({event: existingState});

            }
        })
    }

    formatNotes(notes) {
        if (notes) {
            //first, escape the html
            notes = notes.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;');

            // turn line breaks into html
            notes = notes.replace(/\n/g,'<br/>' )

            return notes;
        }
        else return null;
    }

    render () {
        const event = Object.assign({}, this.props, this.state.event);
        if (this.state.deleted) {
            return false;
        }
        else {
            return (
                <li>
                    <div className="meta">
                        <div className="inBox">
                            <Connect mutation={graphqlOperation(mutations.updateEvent)}>
                                {( {mutation }) => (

                                    <button onClick={() => {
                                        if (this.state.editing) {
                                            mutation({
                                                id: event.id,
                                                slug: this.state.slug,
                                                start: Number(this.state.start)/1000,
                                                end: Number(this.state.end)/1000,
                                                location: (this.state.location.trim() === '')?null:this.state.location,
                                                notes: (!this.state.notes || this.state.notes.trim() === '')?null:this.state.notes,
                                                covering: (!this.state.covering || this.state.covering.trim() === '')?null:this.state.covering,
                                                startDay: moment(this.state.start).startOf('day').unix()
                                            });
                                        }
                                        this.setState({editing:!this.state.editing});
                                    }}>
                                        <FontAwesomeIcon icon="edit" /> {this.state.editing?"Update":"Edit"} Event                                
                                    </button>
                                )}
                                
                            </Connect>
                            {this.state.editing && 
                                <Connect mutation={graphqlOperation(mutations.deleteEvent)}>
                                    {( {mutation }) => (
                                        <button className="danger" onClick={() => {
                                            if (window.confirm(`Are you sure you want to delete "${this.state.slug}"?`)) {
                                                mutation({
                                                    id: event.id
                                                });

                                            }
                                            this.setState({deleted:true})
                                        }}>
                                            <FontAwesomeIcon icon="times-circle" />{' '} 
                                            Delete Event
                                        </button>
                                    )}
                                </Connect>
                            }
                        </div>
                        {this.state.editing && (<input type="text" defaultValue={this.state.slug} onChange={(e) => this.updateState('slug', e)} />)}
                        {!this.state.editing && (<h3>{this.state.slug}</h3>)}
                            
                            {this.props.candidates && 
                                <p className="multicandidates">
                                    {this.props.candidates.map(candidate => (<Connect query={graphqlOperation(getShallowCandidate, {id: candidate})} key={candidate}>
                                        {({loading, error, data:{getCandidate}}) => {
                                            if (loading) return <span>...</span>;
                                            if (error) return <span>Error: {error}</span>
                                            return <Link to={`/candidates/${getCandidate.id}`}>
                                                <img src={getCandidate.photo} alt={getCandidate.name}  className="smallCandidate" title={getCandidate.name} />
                                            </Link>
                                        }}
                                    </Connect>))}
                                </p>
                            }
                            <p>
                                <FontAwesomeIcon icon="clock" fixedWidth className="icon" />
                                {this.state.editing && (
                                <StartAndEndTime start={this.state.start} end={this.state.end} updateFn={this.updateTimes} />
                                )}
                                {!this.state.editing && <>
                                    { moment(this.state.start).format("dddd, MMMM Do") }
                                    {' '}&middot;{' '}
                                    {`${moment(this.state.start).format("h:mm A")} - ${moment(this.state.end).format("h:mm A")}`}
                                    </>
                                }
                            </p>
                            <div className={this.state.editing?"formRow":'formP'}>
                                <FontAwesomeIcon icon="map-marker-alt" fixedWidth  className="icon" />
                                {this.state.editing && (<PlacesAutocomplete
                                        value={this.state.address}                                        
                                        onChange={this.handleLocationChange}
                                        onSelect={this.handleLocationSelect}
                                        searchOptions={{bounds:{
                                            east:-66.885444,
                                            north:49.384358,
                                            south:24.396308,
                                            west:-124.848974
                                        }}}
                                        highlightFirstSuggestion={true}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                            {...getInputProps({
                                                placeholder: 'Location',
                                                className: 'location-search-input',
                                            })}
                                            onBlur={() => this.handleLocationBlur(suggestions)}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                                );
                                            })}
                                            </div>
                                        </div>
                                        )}
                                    </PlacesAutocomplete>)}
                                {!this.state.editing && 
                                <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(this.state.location)}`} target="_new">{this.state.location}
                                    <FontAwesomeIcon className="linkicon icon" icon="external-link-alt"/>
                                </a> }
                            </div>
                            <p>
                                {(this.state.editing || this.state.covering) && <FontAwesomeIcon icon="video" fixedWidth  className="icon" />}
                                {this.state.editing && <input type="text" defaultValue={this.state.covering} placeholder="Source" onChange={(e) => this.updateState('covering', e)}/>}
                                {!this.state.editing && this.state.covering}
                            </p>
                            
                                
                                {this.state.editing && <p className="formRow">
                                        <FontAwesomeIcon icon="book" fixedWidth  className="icon" />
                                        <textarea placeholder="Notes" defaultValue={this.state.notes} onChange={(e) => this.updateState('notes', e)}/>
                                    </p>
                                }

                                {(!this.state.editing && this.state.notes) && 
                                    <p>
                                    {/* there are notes to show */}
                                    <FontAwesomeIcon icon="book" fixedWidth  className="icon" />
                                    <button onClick={() => this.setState({showNote:!this.state.showNote})} className="asLink">
                                        {(this.state.showNote)?"Hide":"Show"} Note <FontAwesomeIcon icon={(this.state.showNote)?"caret-up":"caret-down"} />
                                    </button>
                                    {this.state.showNote && <span dangerouslySetInnerHTML={{__html:this.formatNotes(this.state.notes)}} className="note"/> }                                    
                                    </p>
                                }
                    </div>
                    <ul className="transmissions">
                        {event.transmissions.sort((a,b) => {
                            if (a.start > b.start) return 1;
                            if (a.start < b.start) return -1;

                            if (a.line && b.line) { 
                                if (a.line.src > b.line.src) return 1;
                                if (a.line.src < b.line.src) return -1;
                            }

                            return 0;
                        }).map(tx => (
                            <li key={tx.id}><Transmission {...tx} event_id={this.props.id} event_slug={this.props.slug} /></li>
                        ))}
                        {event.transmissions.length === 0 && <div className="empty">No transmissions scheduled.</div>}
                        <Link to={
                            {
                                pathname:'/feeds/new',
                                state: {
                                    event_id: event.id,
                                    start: event.start*1000,
                                    end: event.end*1000
                                }
                            }} className="button">
                            <FontAwesomeIcon icon="plus-circle" /> Add new transmission
                        </Link>
                    </ul>
                </li>
            )
        }
    }
}

export default Event;
